.accordion {
    @apply mt-8 border-b;

    &__item {
        @apply border-t border-ttd-sand-400 py-3;
    }

    &__button {
        @apply flex items-center;

        &:before {
            @apply inline-block h-2 w-2 mr-3 border-b-2 border-r-2 border-brand-primary rotate-45 transition-all duration-150;
            content: '';
        }

    }
}
/* purgecss ignore */
.accordion__button[aria-expanded="true"]:before,
.accordion__button[aria-selected="true"]:before {
    --tw-rotate: -135deg !important;
}
