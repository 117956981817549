.field-label {
    @apply block text-gray-900 font-semibold;
}

.label-text {
    @apply leading-none block mb-2;
}

.input-field {
    @apply placeholder-gray-500 text-gray-600 rounded-full w-full focus:not-italic px-4 py-3 border border-gray-300 hover:border-brand-primary active:border-brand-primary  transition-shadow focus:text-gray-800 focus:outline-none focus:border-brand-primary focus:ring-2 focus:ring-inset focus:ring-brand-primary;
}

.quantity-input {
    @apply placeholder-gray-500 text-gray-600 w-full text-center px-4 py-3 border border-gray-300 hover:border-brand-primary active:border-brand-primary transition-shadow focus:text-gray-800 focus:outline-none focus:border-brand-primary focus:ring-2 focus:ring-inset focus:ring-brand-primary;
}

textarea.input-field {
    @apply rounded-xl;
}

.dropdown-field {
    @apply form-select placeholder-gray-500 text-gray-600 rounded-full w-full focus:not-italic px-4 py-3 border border-gray-300 hover:border-brand-primary active:border-brand-primary  transition-shadow focus:text-gray-800 focus:outline-none focus:border-brand-primary focus:ring-2 focus:ring-inset focus:ring-brand-primary;
    &:disabled{
        @apply bg-gray-200;
    }
}

label {
    @apply hover:cursor-pointer;
}

input[type=file]::-webkit-file-upload-button,
input[type=file]::file-selector-button {
    @apply px-4 py-3 rounded-tl rounded-bl border-none inline-block text-center font-semibold transition duration-150 ease-in-out bg-brand-primary text-white hover:bg-brand-tertiary active:bg-brand-secondary active:text-brand-tertiary focus:ring-2 focus:ring-inset focus:ring-brand-tertiary hover:cursor-pointer;
    margin-inline-start: -1rem;
    margin-inline-end: 1rem;
}