.btn {
    @apply rounded-full inline-block text-center text-white font-semibold transition duration-150 ease-in-out;
}

.btn-sm {
    @apply text-sm px-4 py-3 leading-none;
}

.btn-md {
    @apply px-4 py-3.5 leading-none;
}

.btn-lg {
    @apply px-7 py-5 leading-none;
}

.btn-block {
    @apply w-full;
}

.btn-primary {
    @apply border border-brand-primary bg-brand-primary text-white hover:cursor-pointer hover:bg-brand-tertiary hover:border-brand-tertiary active:border-ttd-khaki-300 active:bg-ttd-khaki-300 active:text-brand-tertiary focus:ring-2 focus:ring-inset focus:border-ttd-khaki-300 focus:ring-ttd-khaki-300 focus:outline-none;
}

.btn-secondary {
    @apply border border-ttd-grey-300 bg-white rounded-full text-brand-primary hover:bg-brand-primary hover:border-brand-primary hover:text-white hover:cursor-pointer active:border-brand-tertiary active:bg-brand-tertiary active:text-white focus:border-brand-primary focus:ring-2 focus:ring-inset focus:ring-brand-primary focus:text-white focus:bg-ttd-khaki-300 focus:outline-none;
}

.btn-arrow {
    @apply text-brand-tertiary font-medium hover:underline active:text-brand-primary;

    &:after {
        content: '\27F6';
        @apply text-brand-tertiary ml-1 transition-all hover:ml-2 active:text-brand-primary active:underline inline-block;
    }

    &:hover:after {
        @apply ml-2 no-underline;
    }

    // The below animation is for category/range lists, where the image is the main feature of the link

    img {
        @apply transition-all;
    }

    &:hover img {
        @apply scale-110 transition-all duration-500;
    }
}

.btn-white {
    @apply border-2 border-white text-white hover:border-brand-secondary active:border-brand-primary focus:ring-1 focus:ring-brand-secondary focus:border-brand-secondary rounded font-semibold hover:cursor-pointer
}

.btn-added-to-cart {
    @apply bg-brand-primary flex items-center justify-center;
}

.btn-disabled {
    @apply bg-gray-200 text-gray-500 hover:cursor-not-allowed;
}

.btn-add-to-collection {
    @apply border-2 border-brand-primary hover:border-brand-tertiary px-4 py-2 flex items-center justify-center rounded leading-none hover:cursor-pointer;
}

// For inline input/button forms
.btn-inline {
    @apply rounded-none rounded-r-full px-6 flex items-center leading-none hover:cursor-pointer;
}