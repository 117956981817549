.homepage-slider {
	.bordered-slider {
		border: 1px solid rgba(209, 213, 219, var(--tw-border-opacity)) !important;
		border-radius: 0.25rem !important;
	}

	.swiper-slide>div {
		height: 100%;
	}
}

.product-page-thumbnail-slider {
	.product-page-thumbnail-image {
		@apply bg-white w-full object-cover hover:cursor-pointer hover:scale-110 transition-all duration-500;
	}
	// ACTIVE SLIDE STYLES
	.swiper-slide-thumb-active span:before {
		content: "";
		@apply absolute bottom-0 top-0 left-0 right-0 z-10 ring-2 ring-inset ring-white border border-brand-primary;
	}
}

div.swiper-button-prev,
div.swiper-button-next {
	@apply text-white;
}

.small-swiper{
	max-width:320px;
}
.large-swiper{
	max-width:500px;
}
// .homepage-swiper{
// 	max-width:1000px;
// }
.homepage-mobile-swiper{
	max-width:100vw;
}
// @media (min-width: 500px) {
// 	.homepage-mobile-swiper{
// 		max-width:500px;
// 	}
// }
// @media (min-width: 765px) {
// 	.homepage-mobile-swiper{
// 		max-width:765px;
// 	}
// }

/* purgecss start ignore */
.swiper-thumbs .swiper-slide {
    width: auto !important;
}

.swiper-button-next,
.swiper-button-prev {
	@apply flex bg-white text-brand-primary border border-brand-primary hover:bg-brand-primary hover:text-white rounded-full w-12 h-12 active:bg-brand-tertiary active:text-white transition duration-150 ease-in-out;
}

.swiper-button-prev:after {
	@apply mr-1;
}

.swiper-button-next:after {
	@apply ml-1;
}

.swiper-button-prev:after,
.swiper-button-next:after {
	@apply text-2xl;
}

.swiper-pagination-bullet {
	@apply border border-brand-tertiary mx-6 transition duration-150 ease-in-out;

	&:hover {
		@apply bg-brand-tertiary;
	}
}
.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    display: table;
    width: auto;
    margin: 0 auto;
    right: 0;
}

.swiper-block .swiper-button-next {
	right: 150px;
	background: #80808722;
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #fff;

	@media (max-width: 1399px) {
		right: 10px;
	}

	@media (max-width: 1023px) {
		top: auto;
		bottom: 45px;
		right: 50%;
		margin-right: -80px;
		transform: translateX(-50%);
	}
}

.swiper-block .swiper-button-prev {
	left: 150px;
	background: #80808722;
	box-shadow: 0px 3px 6px #00000029;
	border: 1px solid #fff;

	@media (max-width: 1399px) {
		left: 10px;
	}

	@media (max-width: 1023px) {
		top: auto;
		bottom: 45px;
		left: 50%;
		margin-left: -48px;
		transform: translateX(-50%);
	}
}

.swiper-block .swiper-button-next::after,
.swiper-block .swiper-button-prev::after {
	color: #fff;
}

// Styles to override the default swiper slider styles for the product page
.product-page-main-image {
	.swiper-pagination {
		@apply bg-white bg-opacity-90 text-gray-600 px-2 rounded mb-1;
	}
}
/* purgecss end ignore */