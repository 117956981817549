.custom-img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
}
  
.unset-img {
    width: 100%;
}
.unset-img > div {
    position: unset !important;
}