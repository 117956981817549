.overlap-col-1 {
    grid-column: 1 / 13;
    grid-row: 2;
}
.overlap-col-2 {
    grid-column: 1 / 13;
    grid-row: 1;
}
@screen lg {
    .overlap-col-1 {
        grid-column: 1 / 9;
        grid-row: 1;
        @apply p-32;
    }
    .overlap-col-2 {
        grid-column: 6 / 13;
        grid-row: 1;
    }
}