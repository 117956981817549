@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind screens;

// Heading Font
@import url("https://use.typekit.net/iju3pcr.css");

// Body Font
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

// import swiper code here so its visible to swiper compnent and gets pruned accordingly
@import url(swiper-bundle.min.css);

@import "./fontawesome/scss/fontawesome.scss";
@import "./fontawesome/scss/solid.scss";
@import "./fontawesome/scss/brands.scss";

@import "./base/typography.scss";
@import "./base/links.scss";
@import "./base/utilities.scss";

@import "./components/accordion.scss";
@import "./components/buttons.scss";
@import "./components/filter-panels.scss";
@import "./components/footer.scss";
@import "./components/forms.scss";
@import "./components/header.scss";
@import "./components/home-feature-panels.scss";
@import "./components/navigation.scss";
@import "./components/our-showroom.scss";
@import "./components/overlapping-section";
@import "./components/reviews-io.scss";
@import "./components/swiper-slider.scss";
@import "./components/tables.scss";

// these are for old styles forom rb1 tinymce
@import "./rb1-overrides.scss";

// jm overrides here

#__next,
#__next .motion-animate-wrapper:first-child,
#__next .motion-animate-wrapper:first-child .wrapper:first-child {
	min-height: 100vh;
	// min-width:100vw;
}

#mobileMenuDropdown {
	min-height: 400px;
}

.new-update-background {
	background-image: url(/assets/images/bg-new-update.jpg);
}

#gateway_iframe_wrapper iframe {
	width: 100%;
	height: 100%;
	min-height: 100vh;
}

@media (max-width: 1023px) {
	iframe {
		max-width: 100%;
	}
}

.top-80px {
	top:80px
}
/* purgecss start ignore */
.popup-overlay {
    background-color: rgba(0,0,0,0.7);
}
/* purgecss end ignore */

// jm overrides above here

html,
body {
	height: 100%;
}

body {
	font-family: 'Roboto', sans-serif;
	@apply bg-white;
}


.icon {
	display: inline-block;
	font-style: normal;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;

	&.icon-search {
		background-image: url(/assets/images/icon-search.svg);
	}

	&.icon-cart {
		background-image: url(/assets/images/icon-cart.svg);
	}

	&.icon-down-arrow {
		background-image: url(/assets/images/icon-arrow-down.svg);
	}

	&.icon-trustpilot {
		background-image: url(/assets/images/icon-trustpilot-stars-5-1.svg);
	}

	&.icon-360-view {
		background-image: url(/assets/images/icon-360-view.svg);
	}

	&.icon-twitter-square {
		background-image: url(/assets/images/icon-twitter-square.svg);
	}

	&.icon-linkedin-square {
		background-image: url(/assets/images/icon-linkedin-square.svg);
	}

	&.icon-facebook-square {
		background-image: url(/assets/images/icon-facebook-square.svg);
	}

	&.icon-instagram {
		background-image: url(/assets/images/icon-instagram.svg);
	}

	&.icon-add-collection {
		background-image: url(/assets/images/icon-add-collection.svg);
	}

	&.icon-part-identification {
		background-image: url(/assets/images/icon-part-identification.svg);
	}

	&.icon-water-heater {
		background-image: url(/assets/images/icon-water-heater.svg);
	}

	&.icon-water-cylinder {
		background-image: url(/assets/images/icon-water-cylinder.svg);
	}

	&.icon-new-customer {
		background-image: url(/assets/images/icon-new-customer.svg);
	}

	&.icon-add-collection-dark {
		background-image: url(/assets/images/icon-add-collection-dark.png);
	}

	&.icon-arrow-left {
		background-image: url(/assets/images/icon-arrow-left.png);
	}

	&.icon-search-home {
		background-image: url(/assets/images/icon-search-home.png);
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 50%;
	}

	&.icon-twitter-dark {
		background-image: url(/assets/images/icon-twitter-dark.svg);
	}

	&.icon-linkedin-dark {
		background-image: url(/assets/images/icon-linkedin-dark.svg);
	}

	&.icon-facebook-dark {
		background-image: url(/assets/images/icon-facebook-dark.svg);
	}
}

.search {
	.input-search {
		@media (min-width: 1024px) {
			min-width: 35rem;
		}

		&::placeholder {
			/* Chrome, Firefox, Opera, Safari 10.1+ */
			font-style: italic;
		}

		&:-ms-input-placeholder {
			/* Internet Explorer 10-11 */
			font-style: italic;
		}

		&::-ms-input-placeholder {
			/* Microsoft Edge */
			font-style: italic;
		}
	}
}

.dropdown:hover .dropdown-menu {
	display: block;
}

.has-tooltip:hover .has-tooltip-hover\:flex {
	display: flex;
}

.tab-content {
	display: none;
}

.tab-content.active {
	display: block;
}

.bg-img-banner {
	background-position: 100% 0;
	background-repeat: no-repeat;
	background-size: contain;
}

.max-h-0 {
	max-height: 0;
}

input[type=range]::-webkit-slider-thumb {
	pointer-events: all;
	width: 24px;
	height: 24px;
	-webkit-appearance: none;

	/* @apply w-6 h-6 appearance-none pointer-events-auto; */
}

.bg-img-catalogue {
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
}

.opacity-60 {
	opacity: 0.6;
}

.whitespace-no-wrap {
	white-space: nowrap;
}

select.appearance-none {
	appearance: auto;
}

.img-latest-blog {
	span {
		width: 100% !important;
	}
}

.fh-text-color{
	color: #B6B9AD;
}

.text-ttd-beg-500{
	color: #747C61;
}
.text-gray-cart{
	color : #808087;
}

.bg-login-tab{
	background-color: #FAF3EE;
}

.instagram-feed {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: auto;
	overflow-y: hidden;
}
	
#instafeed-container {
	display: flex;
}

#instafeed-container img {
	transition: 0.3s ease-in-out;
	transform: scale(1);
}

#instafeed-container img:hover {
	transform: scale(1.1);
}

.profile-holder {
	display:flex;
	align-items: center;
}

.profile-holder a:focus {
	background: none;
	box-shadow: none;
	outline: none;
}

.profile-wrap {
	display:flex;
	align-items: center;
	margin-right: 25px;

	@media (max-width:767px) {
		margin-right: 15px;
	}
}

.profile-wrap + .profile-wrap {
	margin-right: 0;
}

.profile-wrap .icon-holder {
	margin-right: 5px;
}

.profile-wrap .text {
	display: block;
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
	color: #747C61;

	@media (max-width:767px) {
		display: none;
	}
}

.profile-wrap .text:hover {
	text-decoration: underline;
}

.profile-wrap .text:focus {
	box-shadow: none;
	outline: none;
	background: none;
	-webkit-appearance: none;
}

.basket-count {
	top: 0;
	left: -5px;
}

.showroom-image .main-img {
	transition: 0.3s ease-in-out;
	transform: scale(1);
}

.showroom-image .main-img:hover {
	transform: scale(1.1);
}

.columns-holder {
	@media (min-width:768px) {
		position: relative;
		
		&:after {
			top: 0;
			left: -18px;
			width: 5px;
			content: "";
			position: absolute;
			background: #fff;
			height: 100%;
		}
	}
}

.columns-holder .post-column{
	position: relative;
}

.columns-holder .post-column + .post-column::after {
	top: 0;
	left: -16px;
	bottom: 0;
	width: 1px;
	content: "";
	background: #4C4E56;
	position: absolute;

	@media (max-width:1023px) {
		left: -8px;
	}

	@media (max-width:767px) {
		display: none;
	}
}

.columns-holder .post-column:nth-child(even)::after {
	@media (max-width:767px) {
		top: 0;
		left: -8px;
		bottom: 0;
		width: 1px;
		content: "";
		display: block;
		background: #4C4E56;
		position: absolute;
	}
}

.columns-holder .post-column .img-box {
	position: relative;
}

.columns-holder .post-column .img-box::after {
	top: 0;
	left: 0;
	right: 0;
	bottom: 7px;
	content: "";
	opacity: 0;
	visibility: hidden;
	position: absolute;
	background: rgba(#4C4E56 , 0.17);
}

.columns-holder .post-column:hover .img-box::after {
	opacity: 1;
	visibility: visible;
}

.ship-detail .border {
	border-radius: 32px;
}

.address-list li {
	display: flex;
}

.address-list li i,
.address-list li svg {
	display: block;
	margin-right: 5px;
}

.address-list li .fa-home {
	color: #4C4E56;
	font-size: 16px;
	margin-top: 5px;
}

.address-list .address-txt {
	display: inline-block;
	font-style: normal;
	font-size: 18px;
	line-height: 28px;
	font-weight: 500;
	color: #747C61;
}

.accordion__button::before {
	margin-top: -5px !important;
}
/* purgecss start ignore */

.swiper-block {
	position: relative;
}

.swiper-block .swiper-button-next,
.swiper-block .swiper-button-prev {
	@apply flex bg-white text-brand-primary border border-brand-primary hover:bg-brand-primary hover:text-white rounded-full w-12 h-12 active:bg-brand-tertiary active:text-white transition duration-150 ease-in-out;
}

.swiper-block .swiper-button-next {
	right: 150px !important;
	background: #80808722 !important;
	box-shadow: 0px 3px 6px #00000029 !important;
	border: 1px solid #fff !important;

	@media (max-width: 1399px) {
		right: 10px !important;
	}

	@media (max-width: 1023px) {
		top: auto !important;
		bottom: 45px !important;
		right: 50% !important;
		margin-right: -80px !important;
		transform: translateX(-50%) !important;
	}
}

.swiper-block .swiper-button-prev {
	left: 150px !important;
	background: #80808722 !important;
	box-shadow: 0px 3px 6px #00000029 !important;
	border: 1px solid #fff !important;

	@media (max-width: 1399px) {
		left: 10px !important;
	}

	@media (max-width: 1023px) {
		top: auto !important;
		bottom: 45px !important;
		left: 50% !important;
		margin-left: -48px !important;
		transform: translateX(-50%) !important;
	}
}

.swiper-block .swiper-button-next::after,
.swiper-block .swiper-button-prev::after {
	color: #fff !important;
}

.swiper-button-next:after, .swiper-button-prev:after{
	color:#747c61;
}

.swiper-button-next:hover:after, .swiper-button-prev:hover:after {
	color: #fff;
}

/* purgecss end ignore */
.showroom-text-box {
	padding-top: 180px;
	padding-bottom: 180px;

	@media (max-width:1023px) {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	@media (max-width:767px) {
		padding-top: 25px;
		padding-bottom: 50px;
	}

	h2 {
		font-size: 34px;
		line-height: 44px;

		@media (max-width:1023px) {
			font-size: 29px;
			line-height: 39px;
			font-weight: 500;
		}
	}
}

.showroom-text-holder {
	padding-top: 180px;
	padding-bottom: 180px;

	@media (max-width:1023px) {
		padding-top: 90px;
		padding-bottom: 140px;
	}
}

.showroom-image {
	/*@media (max-width:1023px) {
		height:368px;
	}*/

	span {
		position: static !important;
	}

	img {
		width: 100% !important;
		height: auto !important;
		max-width: auto !important;
		max-height: auto !important;
		position: relative !important;
	}
}

.heading_text {
	@media (max-width:767px) {
		font-size: 23px;
		line-height: 33px;
	}
}

.main_banner {
	position: relative;

	.banner-layout {
		top: 0;
		left: 0;
		bottom: 0;
		width: 448px;
		content: "";
		z-index: 5;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(#4C4E56 , 0.56);
		position: absolute;

		@media (max-width:1023px) {
			top: 0;
			bottom: auto;
			height: 155px;
			width: 100%;
		}
	}

	.wrap {
		overflow: hidden;
	}

	.title-scotland {
		display: block;
		font-size: 53px;
		line-height: 63px;
		font-weight: 500;
		color: #fff;
		margin-bottom: 5px;
		text-align: center;
		font-family: "Albra", "Montserrat", sans-serif;

		@media (max-width:1023px) {
			font-size: 29px;
			line-height: 33px;
		}
	}

	.title-showroom {
		display: block;
		font-size: 34px;
		line-height: 44px;
		font-weight: 600;
		color: #fff;
		position: relative;
		padding: 0 0 20px;
		font-family: "Albra", "Montserrat", sans-serif;

		@media (max-width:1023px) {
			font-size: 23px;
			line-height: 33px;
			padding: 0 0 10px;
		}

		&::after {
			bottom: 0;
			left: 50%;
			width: 48px;
			height: 1px;
			content: "";
			transform: translateX(-50%);
			background: #fff;
			position: absolute;
		}
	}

	.btn-view {
		bottom: 50px;
		left: 20px;
		right: 20px;
		z-index: 5;
		border-radius: 28px;
		min-height: 54px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #747C61;
		position: absolute;

		@media (min-width:768px) {
			display: none;
		}
	}
}

.showroom-container {
	@media (max-width:1023px) {
		display: block;
	}

	.showroom-info-holder {
		display: flex;
		align-items: center;

		@media (max-width:1023px) {
			display: block;
		}
	}

	.showroom-text-info {
		background: #fff;
		padding: 100px 26% 100px 6%;
		width: 60%;
		flex-shrink: 0;

		@media (max-width:1023px) {
			padding: 50px 5%;
			width: 100%;
			margin: 0 0 50px;
		}
	}

	.showroom-image {
		flex-grow: 1;
		margin: 0 0 0 -300px;
		padding: 60px 150px 0 0;

		@media (max-width:1535px) {
			padding: 60px 100px 0 0;
		}

		@media (max-width:1279px) {
			margin: 0 0 0 -200px;
		}

		@media (max-width:1023px) {
			margin: 0;
		}

		span,
		img {
			position: static !important;
		}

		img {
			display: block !important;
			height: auto !important;
			width: 100% !important;
			max-width: none !important;
			max-height: auto !important;
		}

		.corner-img {
			top: 0;
			right: 0;
			width: 36%;
			position: absolute;

			img {
				display: block !important;
				height: auto !important;
				max-width: 100% !important;
			}
		}

		.right-bottom-icon {
			right: 50px;
    		bottom: 70px;
			position: absolute;

			@media (max-width:1550px) {
				right: 25px;
			}

			img {
				display: block !important;
				height: auto !important;
				width: auto !important;
				max-width: 100% !important;
			}
		}
	}

	.caption-text {
		top: 130px;
		left: 70px;
		right: 60px;
		bottom: 70px;
		font-size: 34px;
		line-height: 44px;
		color: #fff;
		display: flex;
		padding: 30px;
		align-items: flex-end;
		position: absolute;
		border: 2px solid #fff;

		@media (max-width:1199px) {
			top: 80px;
			left: 10px;
			right: 10px;
			bottom: 20px;
			padding: 10px 20px;
			font-size: 24px;
			line-height: 28px;
		}
	}
}

.contact_form {
	@media (max-width:767px) {
		margin: 0 -20px;
	}

	.btn {
		@media (max-width:767px) {
			width: 100%;
		}
	}
}

#iffpzo,
#ij0p {
	font-size: 15px !important;
	line-height: 25px !important;
	color: #808087 !important;
}

.fields_note_text {
	display: block;
	font-size: 12px;
	line-height: 22px;
	color: #808087;
	font-weight: 400;
	margin-bottom: 16px;

	@media (min-width:768px) {
		display: none;
	}
}

.checkout-content {
	@media (max-width:1023px) {
		background: #fff;
	}

	.bg-ttd-sand-200 {
		@media (max-width:1023px) {
			background: #fff;
		}
	}
}

.detail-404 {
	display: flex;
	justify-content: center;
	padding: 0 15px;
	margin: 50px 0 20px;

	.btn {
		@media (max-width:767px) {
			display: block;
		}
	}
}

.tabs {
	li {
		border-radius: 8px;
		margin-bottom: 5px;
		position: relative;
	}
	a {
		border: 1px solid #CCCCCE;
		border-radius: 8px;
	}

	.up-arrow {
		right: 20px;
		top: 50%;
		position: absolute;
		transform: translateY(-50%);
	}
}

.detail-popup {
	position: relative;
	&::after {
		top: -32px;
		height: 32px;
		left: 0;
		width: 100%;
		content: "";
		position: absolute;
		z-index: 5;
		background: rgba(#747C61 , 0.75);
	}
}

.content {
	background: #F6F4F2;

	@media (max-width:1023px) {
		background: #fff;
	}
}

.top-links-wrap {
	padding: 10px 0;

	.text-link,
	span {
		font-size: 12px;
		line-height: 24px;
	}

	@media (min-width:767px) {
		display: none;
	}
}

input.form-checkbox {
	width: 14px;
	height: 14px;
	background: #CCCCCE;
	background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

label {
	&:hover,
	&:focus {
		input.form-checkbox {
			border: none;
			outline: none;
			-webkit-appearance: none;
			box-shadow: none;
			--tw-ring-opacity: 0 !important;
    		--tw-ring-color: transparent !important;

			&:focus {
				-webkit-appearance: none;
				box-shadow: none;
				--tw-ring-opacity: 0 !important;
				--tw-ring-color: transparent !important;
			}
		}
	}

	.leading-none {
		font-size: 15px;
		line-height: 18px;
	}
}

.product-heading-wrap {
	display: flex;
	align-items: center;

	.clear-filter {

		@media (max-width: 1023px) {
			display: none;
		}
	}
}

.clear-filter {
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
	color: #747C61;
	display: block;
	margin-left: 40px;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}

	@media (max-width: 1023px) {
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		margin: 25px 0 0;
	}

	&.style {
		@media (min-width: 1024px) {
			display: none;
		}
	}
}

#reviews {
	.columns-holder {
		@media (max-width: 1023px) {
			flex-direction: column;
		}
	}

	.top-align {
		@media (max-width: 1023px) {
			order: 1;
		}
	}

	.bottom-align {
		@media (max-width: 1023px) {
			order: 2;
		}
	}
}

.review_bar {
	justify-content: space-between;
	padding: 0 0 15px;
	margin-bottom:15px;
	border-bottom: 1px solid #E8E4DF;

	.heading-wrap {
		display: flex;
		align-items: center;
		position: relative;

		@media (max-width: 1023px) {
			display: block;
		}
	}

	.arrow-up {
		top: 50%;
		right: 0;
		position: absolute;
		transform: translateY(-50%);

		@media (min-width: 1024px) {
			display: none;
		}
	}

	h2 {
		font-size: 34px;
		line-height: 38px;
		font-weight: 500;
		color: #747C61;
		padding: 0;

		&::after {
			display: none;
		}

		@media (max-width: 1023px) {
			font-size: 23px;
			line-height: 26px;
		}
	}

	.btn {
		padding: 16px 28px 15px;
		font-size: 18px;
		line-height: 20px;
		font-weight: 400;
		display: none;
		@media (max-width: 1023px) {
			display: none;
		}
	}
}

.cart_basket {
	background: #F6F4F2;

	@media (max-width: 1023px) {
		padding-left: 15px;
		padding-right: 15px;
		position: relative;
		margin: 0 -20px;
	}

	.edit {
		bottom: 20px;
		right: 10px;
		position: absolute;
		font-size: 12px;
		line-height: 14px;
		font-weight: 500;
		color: #747C61;
		display: none;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.btn_proceed {
	@media (max-width: 767px) {
		display: block;
		width: 100%;
	}
}

.summary-info-wrap {
	padding: 20px;
	margin-bottom: 25px;
	background: #F6F4F2;
	border: 1px solid #E8E4DF;

	@media (min-width: 1024px) {
		display: none;
	}
}

.desktop-summary-box {
	@media (max-width: 1023px) {
		display: none;
	}
}

.summary-opener {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;

	.left-align {
		display: flex;
		align-items: center;
	}

	.icon {
		display: block;
	}

	.text {
		display: block;
		margin: 0 5px;
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		color: #4C4E56;
	}

	.price-text {
		display: block;
		font-size: 12px;
		line-height: 14px;
		font-weight: 400;
		color: #747C61;
	}
}

.promo-code {
	font-size: 15px;
	line-height: 18px;
	font-weight: 500;
	color: #747C61;
}

.account_detail {
	background: #fff;
	border: 1px solid #CCCCCE;

	@media (max-width: 1023px) {
		border: none;
	}
}

.summary-detail-box {
	@media (min-width: 1024px) {
		display: block !important;
	}
	@media (max-width: 1023px) {
		padding-top: 20px;
	}
}

.btn_continue {
	@media (max-width: 767px) {
		width: 100%;
	}
}

.video-modal {
	width: 61rem !important;
	max-width: none !important;

	.modal-content {
		position: relative;
		padding: 50px 95px;
		background: #4C4E56 0% 0% no-repeat padding-box;
		box-shadow: 0px 11px 16px #00000029;

		@media (max-width: 1023px) {
			padding: 50px 30px;
		}
	}

	.btn-close {
		top: 20px;
		right: 20px;
		position: absolute;
	}

	.video {
		height: 441px;
	}

	iframe {
		width: 100%;
		height: 100%;
	}
}

.profile-modal {
	width: 79rem !important;

	@media (max-width: 767px) {
		height: 100vh;
		overflow: auto;
		margin: 25px 0;
	}

	.modal-content {
		padding: 32px;
		background: #f4ede3;
		margin: 0 30px;
		position: relative;

		@media (max-width: 1023px) {
			padding: 25px;
		}
	}

	.btn-close {
		top: 10px;
		right: 10px;
		position: absolute;
	}

	.inner-content {
		width: 100%;
		position: relative;
		z-index: 5;
		border: 1px solid #fff;
		padding-left: 345px;

		@media (max-width: 1023px) {
			padding-left: 0;
			padding-top: 240px;
		}
	}

	.profile-image {
		top: 0;
		left: 0;
		width: 380px;
		height: 100%;
		position: absolute;
		background-position: 50% 50%;

		@media (max-width: 1023px) {
			height: 240px;
			width: 100%;
			background-position: 50% 12%;
		}

		.t-icon {
			top: 50%;
			right: -50px;
			position: absolute;
			max-width: 100%;
			transform: translateY(-50%);

			@media (max-width: 1023px) {
				top: auto;
				right: 0;
				left: 50%;
				bottom: -45px;
				transform: translateY(0);
				transform: translateX(-50%);
			}
		}
	}

	.profile-info {
		padding: 50px 100px 50px 120px;
		text-align: left;

		@media (max-width: 1023px) {
			padding: 50px 20px;
		}
	}

	h2 {
		margin: 0 0 25px;
		font-size: 34px;
		line-height: 44px;
		font-weight: 400;
		color: #747C61;

		@media (max-width: 1023px) {
			font-size: 23px;
			line-height: 33px;
		}
	}

	.wrap {
		font-size: 18px;
		line-height: 30px;
		font-weight: 400;
		color: #808087;

		@media (max-width: 1023px) {
			font-size: 15px;
			line-height: 25px;
		}

		p {
			margin: 0 0 20px;

			&:last-child {
				margin: 0;
			}
		}
	}
}

.related-products {
	position: relative;
	padding: 20px 0 0;

	.btn-arrow-left,
	.btn-arrow-right {
		top: -50px;
		right: 200px;
		cursor: pointer;
		position: absolute;

		@media (max-width: 1023px) {
			top: 0;
			right: 50px;
		}
	}

	.btn-arrow-left {
		right: 230px;

		@media (max-width: 1023px) {
			right: 80px;
		}
	}

	&.insta-products {
		.btn-arrow-left,
		.btn-arrow-right {
			top: 0;
		}
	}
}

.product-images-frame {
	position: relative;

	&:after {
		left: 0;
		right: 0;
		bottom: 0;
		content: "";
		position: absolute;
		height: 18px;
		background: #fff;
	}

	&.gray-scroll {
		&:after {
			background: #F6F4F2;
		}
	}
}
	
.product-container {
	display: flex;
	white-space: nowrap;

	.flex-col {
		width: 230px !important;
		display: inline-block;
	}
}

.progress_value {
	@media (max-width: 1023px) {
		top: -5px;
    	right: 10px;
		position: absolute;
	}
}

.review-box {
	background: #FBFBFA;	
}

.bg-grey {
	background: #F6F4F2;
}

.item_title {
	white-space: normal;
}

/* purgecss start ignore */
h1 {
    @media (min-width: 1024px) {
		font-size: 2rem !important;
	}
}
.footer-brand-bar {
	z-index: 0;
}
.right-image {
	z-index: 3;
}

.menu-image img {
	width: 100% !important;
	height: auto !important;
	max-height: auto !important;
	min-height: auto !important;
}
.category-image-holder {
	height: 17em;
}
/* purgecss end ignore */