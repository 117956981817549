.page-content {
    word-break: break-word;

    table {
        @apply overflow-x-scroll max-w-full my-6 text-gray-600;

        th {
            @apply bg-brand-primary text-white font-semibold p-2 lg:p-4 text-left;
        }

        tbody {
            tr {
                @apply border-r border-gray-300 bg-white even:bg-gray-50 hover:bg-gray-100 hover:text-gray-900;


            }
            td {
                @apply border-b border-l border-gray-300 p-2 lg:p-4 hover:bg-gray-200;
            }
        }
    }
}