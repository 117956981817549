@font-face {
    font-family: 'Albra';
    font-weight: 500;
    src: url('../../fonts/medium/albra-medium.eot');
    src: local('Albra Medium'), local('Albra-Medium'),
         url('../../fonts/medium/albra-medium.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/medium/albra-medium.woff2') format('woff2'), 
         url('../../fonts/medium/albra-medium.woff') format('woff'), 
         url('../../fonts/medium/albra-medium.ttf') format('truetype'),
         url('../../fonts/medium/albra-medium.svg#Albra') format('svg'); 
}

@font-face {
    font-family: 'Albra';
    font-weight: 600;
    src: url('../../fonts/semi-bold/albra-semi.eot');
    src: local('Albra Semi'), local('Albra-Semi'),
         url('../../fonts/semi-bold/albra-semi.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/semi-bold/albra-semi.woff2') format('woff2'), 
         url('../../fonts/semi-bold/albra-semi.woff') format('woff'), 
         url('../../fonts/semi-bold/albra-semi.ttf') format('truetype'),
         url('../../fonts/semi-bold/albra-semi.svg#Albra') format('svg'); 
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Albra', 'Montserrat', sans-serif;
    font-weight: 500;
}

h1 em, h2 em, h3 em, h4 em, h5 em, h6 em {
    @apply italic;
}

h1 {
    @apply text-4xl;

    @screen lg {
        @apply text-5xl leading-snug;
    }
}

h2 {
    @apply text-3xl;

    @screen lg {
        @apply text-4xl;
    }
}

h3 {
    @apply text-2xl;

    @screen lg {
        @apply text-3xl;
    }
}

h4 {
    @apply text-xl;

    @screen lg {
        @apply text-2xl;
    }
}

h5 {
    @apply text-lg;

    @screen lg {
        @apply text-xl;
    }
}

h6 {
    @apply text-base;

    @screen lg {
        @apply text-lg;
    }
}

.decorative-heading:after {
    content: '';
    @apply block border-b border-brand-primary w-16 mt-3;
}

.section-label {
    @apply text-sm uppercase tracking-wide text-brand-primary pl-8 pt-8 border-l border-brand-primary mb-8;
}