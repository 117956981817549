.footer-brand-bar {
    @apply relative;

    :after {
        @apply absolute top-0 right-0 bottom-0 left-0 bg-cover filter contrast-200 opacity-30 z-10;
        background: url(../../images/footer-bg-min.jpg) no-repeat center center;
        content: "";
    }
}

.legal-links {
    a {
        @apply border-l border-gray-500 ml-2 pl-2;

        &:first-child {
            @apply border-none ml-0 pl-0;
        }
    }
}